<template>
  <referentiel-list
    :listTitle="'Liste des roles'"
    :referentielName="referentielName"
    :tableFields="tableFields"
    :emptyReferentielItem="emptyReferentielItem"
    :dataFormatter="dataFormatter"
    @activeReferentielChange="handleActiveReferentielChanged"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.name"
                @input="handleInput"
                type="text"
                class="form-control"
                id="name"
                name="name"
                placeholder="Privilège"
              />
              <label for="nom">Libelle</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form mb-3">
              <!-- <div class="field">
              <p-multi-select class="wrap" v-model="editableItem.privileges" display="chip" optionLabel="name" :options="privileges"/>
            </div> -->
              <d-pick-list v-model="items">
                <template #item="{ item }">
                  {{ item.name }}
                </template>
              </d-pick-list>
              <!-- <input :value="editableItem.name" @input="handleInput" type="text" class="form-control" id="nom" name="nom" placeholder="Privilège"> -->
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import DPickList from "../../../components/common/DPickList.vue";
export default {
  data() {
    return {
      items: [],
    };
  },
  components: {
    ReferentielList,
    DPickList,
  },
  computed: {
    ...mapGetters({
      privileges: "privilege/privileges",
    }),
    referentielName() {
      return "role";
    },
    emptyReferentielItem() {
      return {
        name: null,
        privileges: [],
      };
    },
    tableFields() {
      return ["#", "index", { key: "name", label: "libelle" }, "actions"];
    },
    selection() {
      return [[], []];
    },
  },
  watch: {
    privileges() {
      this.items = [this.privileges, []];
    },
  },
  created() {
    this.fetchPrivileges({
      pageNumber: 0,
      pageSize: 2000,
    });
  },
  methods: {
    ...mapActions({
      fetchPrivileges: "privilege/fetchPrivileges",
    }),
    dataFormatter(item) {
      item = {
        ...item,
        name:
          item.name && item.name.toUpperCase().startsWith("ROLE_")
            ? item.name.toUpperCase()
            : "ROLE_" + item.name.toUpperCase(),
        privileges: this.items[1],
      };
      return item;
    },
    handleActiveReferentielChanged(item) {
      const itemIds = item.privileges.map((el) => el.id);
      this.items = [
        this.privileges.filter((it) => !itemIds.includes(it.id)),
        item.privileges,
      ];
    },
  },
};
</script>

<style></style>
